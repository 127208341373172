<template>
	<div class="mainarea">
		<el-row :gutter="20">
			<el-col :span="10" class="passwordleft">
				<h3>修改密码</h3>
				<el-form ref="passChangeForm" :rules="rules" :model="passChangeForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="旧密码:" prop="password"><el-input show-password v-model="passChangeForm.password" placeholder="请输入原密码"></el-input></el-form-item>
					<el-form-item label="新密码:" prop="newPassword">
						<el-input show-password v-model="passChangeForm.newPassword" placeholder="请输入新密码"></el-input>
					</el-form-item>
					<el-form-item label="确认新密码:" prop="plainPassword">
						<el-input show-password v-model="passChangeForm.plainPassword" placeholder="请输入确认密码"></el-input>
					</el-form-item>
					<el-form-item style="text-align: right;">
						<el-button type="primary" @click="doChangePass()">保存</el-button>
						<el-button @click="resetForm('ruleForm')">重置</el-button>
					</el-form-item>
				</el-form>
			</el-col>
			<el-col :span="14" class="passwordleft">
				<div style="float: left;">
					<h5>友情提示：</h5>
					<p>1、新密码录入长度为6-18位</p>
					<p>1、新密码至少包含一个特殊字符</p>
					<p>1、新密码至少包含一个大写和一个小写字母</p>
					<p>如：Yx@123</p>
				</div>
				<img style="float: right;" src="../../assets/images/changePassword.png" />
			</el-col>
		</el-row>
	</div>
</template>

<script>
export default {
	data() {
		return {
			passChangeForm: {
				password: null,
				newPassword: null,
				plainPassword: null
			}
		};
	},
	computed: {
		rules() {
			const checkPass = (rule, value, callback) => {
				if (this.passChangeForm.newPassword && this.passChangeForm.plainPassword && this.passChangeForm.newPassword !== this.passChangeForm.plainPassword) {
					callback(new Error('两次密码不一致，请修改'));
				} else {
					callback();
				}
			};
			return {
				password: [{ required: true, message: '原密码不能为空', trigger: 'change' }],
				newPassword: [{ required: true, message: '新密码不能为空', trigger: 'change' }],
				plainPassword: [{ required: true, message: '确认密码不能为空', trigger: 'change' }, { validator: checkPass, trigger: ['blur', 'change'] }]
			};
		}
	},
	methods: {
		doChangePass() {
			const param = Object.assign({}, this.passChangeForm);
			param.uuid = sessionStorage.getItem('userUuid');
			console.log(param);
			this.$axios.post('/api/web/user/changePwd', param).then(response => {
					if (response.data.code === 200) {
						this.$message.success('修改成功');
					} else {
						this.$message.error(response.data.message);
					}
				}).catch(e => {
					console.log(e);
					//this.$message.error('请求失败');
				});
		}
	}
};
</script>

<style scoped="scoped">
.passwordleft {
	padding: 5px 50px !important;
	border-right: 1px solid #d9d9d9;
	margin-top: 30px;
}
/deep/.el-form-item__label,
.passwordleft p {
	font-size: 12px;
}
/deep/.el-form-item__label {
	font-weight: bold;
}
</style>
